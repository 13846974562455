import axiosInstance from 'http/axiosConfig';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';

const WeeklyReportsView = ({ period = 'week' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [report, setReport] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get('/get-recurring-reports/');
        var report = '';

        if (response.data.status === 'OK') {
          if (period === 'week') {
            report = response.data.recurring_reports.weekly_report;
          }
          if (period === 'month') {
            report = response.data.recurring_reports.monthly_report;
          }
        } else {
          report = '';
          toast.warning(`There is no reports yet`, {
            position: 'top-right',
            autoClose: 20000
          });
        }

        console.log(report);
        setReport(report);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        // Handle error
      }
    };

    fetchInitialData();
  }, [period]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <Row className="g-3 mb-3">
              <Col md={2}></Col>
              <Col md={8}>
                <div
                  className="recurringReportContainer"
                  dangerouslySetInnerHTML={{ __html: report }}
                ></div>
              </Col>
              <Col md={2}></Col>
            </Row>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};
WeeklyReportsView.propTypes = {
  period: PropTypes.string
};
export default WeeklyReportsView;
